<template>
  <div>
    <a-card title="道口管理">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.search" placeholder="名称, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select v-model="searchForm.production_line" placeholder="生产线" allowClear style="width: 100%" @change="search">
            <a-select-option value="a">名创 A</a-select-option>
            <a-select-option value="b">名创 B</a-select-option>
            <a-select-option value="c">名创 C</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 100px; float: right">
          <a-button type="primary" icon="plus" style="width: 100%" @click="createModalVisible = true">新增</a-button>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            @change="changeTable"
          >
            <template slot="action" slot-scope="value, item">
              <table-action :item="item" @update="update" @destroy="destroy" />
            </template>
            <template slot="sorting_quantity" slot-scope="value, item">
              <a-badge
                v-if="item.max_sorting_quantity && value > item.max_sorting_quantity"
                status="error"
                :text="parseFloat(value.toFixed(3))"
              />
              <a-badge v-else status="success" :text="parseFloat(value.toFixed(3))" />
            </template>
            <template slot="sorting_weight" slot-scope="value, item">
              <a-badge
                v-if="item.max_sorting_weight && value > item.max_sorting_weight"
                status="error"
                :text="parseFloat(value.toFixed(3))"
              />
              <a-badge v-else status="success" :text="parseFloat(value.toFixed(3))" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <create-form-modal v-model="createModalVisible" @create="create" @cancel="createModalVisible = false" />
  </div>
</template>

<script>
import { insertItem, replaceItem, removeItem } from "@/utils/functions";
import { crossingList } from "@/apis/data";

export default {
  components: {
    CreateFormModal: () => import("./CreateFormModal"),
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: "name" },
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "名称",
          dataIndex: "name",
          sorter: true,
        },
        {
          title: "生产线",
          dataIndex: "production_line_display",
        },
        {
          title: "道口号",
          dataIndex: "number",
          sorter: true,
        },
        {
          title: "con",
          dataIndex: "con_number",
          sorter: true,
        },
        {
          title: "当前分拣数量",
          dataIndex: "sorting_quantity",
          scopedSlots: { customRender: "sorting_quantity" },
        },
        {
          title: "当前分拣重量(kg)",
          dataIndex: "sorting_weight",
          scopedSlots: { customRender: "sorting_weight" },
        },
        {
          title: "最大分拣数量",
          dataIndex: "max_sorting_quantity",
        },
        {
          title: "最大分拣重量(kg)",
          dataIndex: "max_sorting_weight",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 280,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      createModalVisible: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      crossingList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    create(item) {
      this.dataItems = insertItem(this.dataItems, item);
    },
    update(item) {
      this.dataItems = replaceItem(this.dataItems, item);
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
